import {inject, Injectable} from '@angular/core';
import {ProfileStoreService} from "@features/profile/framework/profile-store.service";
import {UserApiService} from "@features/users/infrastructure/services/user-api/user-api.service";
import {Router} from "@angular/router";
import {LogoutService} from "@features/auth/application/logout/logout.service";
import { User } from '@features/users/domain/User';

@Injectable({
  providedIn: 'root'
})
export class LoadProfileService {
  profileStore = inject(ProfileStoreService)
  userApi = inject(UserApiService);
  router = inject(Router);
  logoutAction = inject(LogoutService);
  constructor() { }

  async run() {
    let response: User;
    try {
      response = await this.userApi.getMyData();
    } catch (err) {
      this.logoutAction.run().catch(console.log);
      await this.router.navigateByUrl('/');
      return;
    }
    this.profileStore.patchState({
      user: response
    })
  }
}
